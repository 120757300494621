






























import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import { PortalTarget } from 'portal-vue';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import Session from '@/models/graphql/Session';
import PollComponent from '@/components/poll/PollComponent.vue';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import GenericEvent from '@/utils/types/GenericEvent';
import SessionSettingType from '@/utils/enums/SessionSettingType';

const notificationStore = namespace('NotificationStore');

@Component({
  components: {
    PollComponent,
    PortalTarget,
  },
})
export default class PollsWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget, VueBaseNotify) {
  @notificationStore.Mutation
  unsubscribeGenericEventPointer!: (channel: string) => void;

  @Prop({
    required: false,
    default: null,
  })
  private sessionUid!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private data!: Session[];

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private sessionCanShowPolls = true;

  private parentSessionUid: string | null = null;

  private get uid(): string | null {
    let { sessionUid } = this;
    if (this.payload && 'sessionUid' in this.payload && this.payload.sessionUid) {
      sessionUid = this.payload.sessionUid as string;
    }
    let r = sessionUid;
    if (sessionUid) {
      const matches = sessionUid.match(/(%[a-zA-Z-_]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          r = r.replaceAll(m, this.$route.params[prop]);
        });
      }
      return r;
    }
    return this.sessionUid;
  }

  private get isPollFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_LIVE_POLLS_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_LIVE_POLLS_FEATURE).enabled
        && this.sessionCanShowPolls;
  }

  created(): void {
    this.notifyEvents = [
      NotificationEventType.SESSION_SETTINGS_CHANGE,
    ];
    if (this.payload && this.uid) {
      this.genericEvent({ channel: `session-settings-${this.uid}` });
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'session',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.SESSION_FILTER,
            value: { uid: this.uid },
          },
        }),
        fragmentName: 'sessionForPollAndQnAFragment',
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing params in payload to identify the session for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }

  beforeDestroy(): void {
    if (this.uid) {
      this.unsubscribeGenericEventPointer(`session-settings-${this.uid}`);
    }
  }

  protected notificationCallback(event: GenericEvent): void {
    if (event && event.type === NotificationEventType.SESSION_SETTINGS_CHANGE) {
      const extraData = JSON.parse(event.extra);
      if (this.uid && event.entityId === this.uid
          && extraData.settingType
          && extraData.settingType === SessionSettingType.POLLS) {
        this.sessionCanShowPolls = extraData.isActivated;
      }
    }
  }

  @Watch('data')
  private setAllowPollForSession(): void {
    if (this.data.length > 0) {
      this.sessionCanShowPolls = !!this.data[0].showPolls;
      if (this.data[0].parentSession && this.data[0].parentSession.uid) {
        this.parentSessionUid = this.data[0].parentSession.uid;
      }
    }
  }
}
